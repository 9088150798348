import { createMuiTheme } from "@material-ui/core/styles";

export const secondary = {
  50: '#e0ebf6',
  100: '#b3cdea',
  200: '#80abdc',
  300: '#4d89cd',
  400: '#2670c3',
  500: '#0057b8',
  600: '#004fb1',
  700: '#0046a8',
  800: '#003ca0',
  900: '#002c91',
  A100: '#bdcbff',
  A200: '#8aa3ff',
  A400: '#577cff',
  A700: '#3d68ff',
  'contrastDefaultColor': 'light',
};

export const primary = {
  50: '#f8e0f0',
  100: '#eeb3d8',
  200: '#e380bf',
  300: '#d74da5',
  400: '#cf2691',
  500: '#c6007e',
  600: '#c00076',
  700: '#b9006b',
  800: '#b10061',
  900: '#a4004e',
  A100: '#ffcee2',
  A200: '#ff9bc4',
  A400: '#ff68a5',
  A700: '#ff4f96',
  'contrastDefaultColor': 'light',
};

const defaultTheme = createMuiTheme({})
const { breakpoints, typography: { pxToRem } } = defaultTheme
const theme = createMuiTheme({
  
  palette: {
    primary: primary,
    secondary: secondary
  },
  
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor:"#7300C3"
        }
      }
    }
  }
});

export default theme;